import React from "react"
import Img from "gatsby-image"
import Flickity from 'react-flickity-component'

class Slider extends React.Component 
{
    componentDidUpdate = () => {
        let slide = this.props.slide;
        if (slide !== undefined) {
            this.flkty.select( slide, false, true );
        }
    }

    render () {
        const entry = this.props.data;
        const flickityGalleryOptions = {
            prevNextButtons: true,
            pageDots: false,
            cellSelector: '.slider__gallery-slider-cell',
            wrapAround: false,
            setGallerySize: false,
            cellAlign: 'center',
            autoPlay: false
        }
        return (
            <Flickity 
            flickityRef={c => this.flkty = c}
            className={'slider slider__gallery-slider'}
            elementType={'div'}
            options={flickityGalleryOptions}
            disableImagesLoaded={false}
            reloadOnUpdate={true}
            static={true}
            >
                {entry.images.map((slides, i) => {
                    return (
                    <div key={i} className="slider__gallery-slider-cell">
                        <div className="slider__gallery--caption">
                            <p className="slider__gallery--text">{slides.caption}</p>
                        </div>
                        <Img imgStyle={{ objectFit: 'contain' }} fluid={slides.image.large.fluid} alt={slides.caption} />
                    </div>
                    )
                })}                            
            </Flickity>
        )
    }
}

export default (props) => (
    <Slider slide={props.selectedIndex} data={props.images} />
)