import React from "react"
import Slider from '../components/slider'
import Img from "gatsby-image"

class Gallery extends React.Component 
{
    constructor(props) {
        super(props);
        this.state = {
            gallery: false,
            selectedIndex: 0,
        }
    }

    handleGallery = (e, i) => {
        e.preventDefault();
        this.setState(prevState => ({
            gallery: !prevState.gallery
        }));

        if (i !== undefined) {
            this.setState({
                selectedIndex: i
            })
        }
    }

    render () {
        const images = this.props.data;
        return (
            <div className="layout">
                <div className="layout body">
                    <div className="layout__container">
                        <div className="layout__wide">
                            <div className="grid grid__3 grid__gap--48 gallery__list">
                                {images.images.map( (entry, i) => (
                                    <button key={i} className="open" onClick={(e) => this.handleGallery(e, i)}>
                                        <Img fluid={entry.image.small.fluid} alt={entry.caption} />
                                        <h3>{entry.caption}</h3>
                                    </button>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
                <div className={this.state.gallery ? `modal__container` : `modal__container--hidden`}>
                    <div className="modal__overlay"></div>
                    <div className="modal">
                        <div className="close">
                            <button className="close__link" onClick={(e) => this.handleGallery(e)}>
                                Close ×
                            </button>
                        </div>
                        <div className="modal__content">
                            <Slider selectedIndex={this.state.selectedIndex} images={images} />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default (images) => (
    <Gallery data={images} />
)